
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import FENZACCI_BASE_URL from '../../config';

export const getCartData = createAsyncThunk(
  'getCartData',
  async (_, { dispatch }) => {
    try {
      const response = await fetch(`${FENZACCI_BASE_URL}/cart/fenzacci/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      console.log('this is befor the tranform cart data =============', data)

      const transformedData = data.map(item => {
        const productDetails = item.product || {};
        const qty = 30;
        return {
          id: item.id,
          name: productDetails.product_name_en , 
          color: item.product_colors , 
          size: item.product_sizes , 
          quant: item.quantity,
          productprice: productDetails.regular_price ,
          curr:productDetails.currency , 
          productimg: productDetails.productImage, 
          stockQty: qty ,
        };
      });
      dispatch(setCartData(transformedData));
      return transformedData;
    } catch (error) {
      console.error('Error:', error);
    }
  }
);

const getCartSlice = createSlice({
  name: 'getCart',
  initialState: {
    cart: [],
  },
  reducers: {
    setCartData: (state, action) => {
      state.cart = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCartData.fulfilled, (state, action) => {
    });
  },
});

export const { setCartData } = getCartSlice.actions;

export default getCartSlice.reducer;
