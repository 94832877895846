
import { createSlice } from '@reduxjs/toolkit';

const productVaritionSlice = createSlice({
  name: 'ProductOptions',
  initialState: {
    quant: 1,
    selectedSize: [],
    selectedColor: [],
    selectedImage: null,
    cart: JSON.parse(localStorage.getItem('cart')) || [],
    subTotal:0,
    wasPriceTotal:0,
    totalDiscount:0,
    totalItems:0,
    vatTax:0,
    grantTotal:0,
  },
  
  reducers: {
    setSelectedSize: (state, action) => {
      state.selectedSize = action.payload;
    },
    setSelectedColor: (state, action) => {
      state.selectedColor = action.payload;
    },
    setSelectedImage: (state, action) => {
      state.selectedImage = action.payload;
    },
    incrementQuantity: (state) => {
      state.quant += 1;
    },
    decrementQuantity: (state) => {
      if (state.quant > 1) {
        state.quant -= 1;
      }
    },

    addToCart: (state, action) => {
      
      if (action.payload.size === undefined || action.payload.size.length === 0) {
        return;
      }

      if (action.payload.colorImage && (action.payload.color === undefined || action.payload.color.length === 0)) {
        return;
      }

      let find = state.cart.findIndex((item) =>
        item.id === action.payload.id &&
        (item.color.length === 0 || !action.payload.color || item.color === action.payload.color)
      );
      if (find >= 0) {
        state.cart[find].quant += action.payload.quant;
      } else {
        state.cart.push(action.payload);
        
      }
        localStorage.setItem('cart', JSON.stringify(state.cart));
    },

    cartItemCalculations: (state) => {
      const subTotal = state.cart.reduce((priceTotal, item) => {
        return priceTotal + parseFloat(item.productprice) * item.quant;
      }, 0);
    
      const wasPriceTotal = state.cart.reduce((wasTotal, item) => {
        return wasTotal + parseFloat(item.wasPrice) * item.quant;
      }, 0);
    
      const totalDiscount = state.cart.reduce((discountTotal, item) => {
        return discountTotal + parseFloat(item.totalSavingAmount) * item.quant;
      }, 0);
    
      const totalItems = state.cart.reduce((total, item) => {
        return total + item.quant;
      }, 0);
    
      const vatTax = subTotal * 0.05;
      // Update state
      state.subTotal = subTotal.toFixed(2);
      state.wasPriceTotal = wasPriceTotal.toFixed(2);
      state.totalDiscount = totalDiscount.toFixed(2);
      state.totalItems = totalItems;
      state.vatTax = vatTax.toFixed(2); 
      state.grantTotal = (subTotal + vatTax).toFixed(2); 
      // Update local storage
      localStorage.setItem('cart', JSON.stringify(state.cart));
      localStorage.setItem('subTotal', JSON.stringify(subTotal));
      localStorage.setItem('wasPriceTotal', JSON.stringify(wasPriceTotal));
      localStorage.setItem('totalDiscount', JSON.stringify(totalDiscount));
      localStorage.setItem('totalItems', JSON.stringify(totalItems));
      localStorage.setItem('vatTax', JSON.stringify(vatTax));
      localStorage.setItem('grantTotal', JSON.stringify(subTotal + vatTax)); 
    },
    
    removeItem: (state, action) => {
      state.cart = state.cart.filter((item) =>
        !(item.id === action.payload.id &&
          item.size === action.payload.size &&
          (action.payload.color !== undefined && action.payload.color.length > 0 ? item.color === action.payload.color : true))
      );
      productVaritionSlice.caseReducers.cartItemCalculations(state);
    },
    

    increaseItemQuantity: (state, action) => {
      state.cart = state.cart.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            quant: item.quant + 1
          };
        }
        return item;
      });
    },
    
    decreaseItemQuantity: (state, action) => {
      state.cart = state.cart.map((item) => {
        const shouldDecrement = (((item.id)) === ((action.payload.id)) &&
          (action.payload.color !== undefined && action.payload.color.length > 0 ? item.color === action.payload.color : true) &&
          item.size === action.payload.size);
        return {
          ...item,
          quant: shouldDecrement ? Math.max(1, item.quant - 1) : item.quant
        };
      });
    },
  },
});

export const {
  setSelectedSize,
  setSelectedColor,
  setSelectedImage,
  incrementQuantity,
  decrementQuantity,
  addToCart,
  removeItem,
  increaseItemQuantity,
  decreaseItemQuantity,
  cartItemCalculations
} = productVaritionSlice.actions;

export default productVaritionSlice.reducer;







