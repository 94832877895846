import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FENZACCI_BASE_URL from "../../config";
import Styles from "./termsConditions.module.css";

const TermsConditions = () => {
  const { slug } = useParams();
  const [termData, setTermData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const controller = new AbortController();

    async function fetchTermConditionBySlug() {
      try {
        const response = await fetch(`${FENZACCI_BASE_URL}/terms/${slug}/`, {
          signal: controller.signal,
        });

        if (!response.ok) {
          throw new Error("Failed to fetch the term and condition");
        }
        const data = await response.json();
        setTermData(data);
      } catch (error) {
        if (error.name !== "AbortError") {
          setError(error.message);
          console.error("Error fetching the term and condition:", error);
        }
      } finally {
        setLoading(false);
      }
    }

    fetchTermConditionBySlug();

    return () => {
      controller.abort();
    };
  }, [slug]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={Styles.termsConditionsContainer}>
        <div className={Styles.termsSubContainer}>
            {termData ? (
                <div>
                    <h1>{termData.content_title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: termData.content_body }} />
                </div>
            ) : (
                <div>No terms and condition</div>
            )}
        </div>
    </div>
);

};

export default TermsConditions;
