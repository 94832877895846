// Import necessary modules
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FENZACCI_BASE_URL from '../../config';

const UAE_COUNTRY_CODE = '+971';
const UAE_CITIES = [
  'Abu Dhabi',
  'Ajman',
  'Al Ain',
  'Dubai',
  'Fujairah',
  'Ras al-Khaimah',
  'Sharjah',
  'Umm al-Quwain',
];


export const postAddress = createAsyncThunk('postAddress', async (userAddress) => {
  try {
    const updatedUserAddress = {
      ...userAddress,
      country_code: UAE_COUNTRY_CODE,
      cities: UAE_CITIES,
    };
    const response = await fetch(`${FENZACCI_BASE_URL}/cart/shipping-address/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedUserAddress),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
});


const addressOrderSlice = createSlice({
  name: 'OrderAddress',
  initialState: {
    userAddress: {},
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(postAddress.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postAddress.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userAddress = action.payload;
      state.error = null;
    });
    builder.addCase(postAddress.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default addressOrderSlice.reducer;