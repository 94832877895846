
import AmercaImg from "../../fenzacciAssets/payment/AMERCA.png";
import DnirImg from "../../fenzacciAssets/payment/DINR.png";
import DiscovrImg from "../../fenzacciAssets/payment/DISCOVER.png";
import JcbImg from "../../fenzacciAssets/payment/JCB.png";
import VisaImg from "../../fenzacciAssets/payment/VISA.png";
import masterCardImg from "../../fenzacciAssets/payment/MASTER.png";

import Styles from './paymentAccept.module.css'


const PaymentAccept = () => {
  return (
    <div className={Styles.paymentAcceptContainer}>
      <div className={Styles.paymentAcceptTitle}>
        <h3> We Accept</h3>
      </div>
      <div className={Styles.paymentImages}>
        <ul>
          <li><img src={VisaImg} alt="Fenzacci Logo" /> </li>
          <li><img src={masterCardImg} alt="Fenzacci Logo" /></li>
          <li> <img src={JcbImg} alt="Fenzacci Logo" /></li>
          <li> <img src={AmercaImg} alt="Fenzacci Logo" /></li>
          <li><img src={DnirImg} alt="Fenzacci Logo" /></li>
          <li><img src={DiscovrImg} alt="Fenzacci Logo" /></li>
        </ul>

      </div>
    </div>
  )
}
export default PaymentAccept