import Styles from "./footer.module.css";
import { BiLogoGmail } from "react-icons/bi";
import { PiPhoneTransferFill } from "react-icons/pi";
import { ImLocation2 } from "react-icons/im";
import HumanRight from "../../../src/fenzacciAssets/humanRight.png";
import { useSelector } from "react-redux";
import FENZACCI_BASE_URL from "../../config";
import PaymentAccept from "../acceptPayment/PaymentAccept";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const FenzacciFooter = () => {
  const footerSocial = useSelector((state) => state.socialMedia.data);
  const [termData, setTermData] = useState([]);


  useEffect(() => {
    const controller = new AbortController();

    async function fetchTermConditions() {
      try {
        const response = await fetch(`${FENZACCI_BASE_URL}/terms/`, {
          signal: controller.signal,
        });

        if (!response.ok) {
          throw new Error("Failed to fetch terms and conditions");
        }
        const data = await response.json();
        setTermData(data); 
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error fetching terms and conditions:", error);
        }
      }
    }

    fetchTermConditions();

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div className={Styles.footerMainContainer}>
      <div className={Styles.footerSubContainer}>
        <div className={Styles.row}>
          <div className={Styles.contactInfoContainer}>
            <div className={Styles.helpTitle}>
              <h2>We're Always Here To Help</h2>
            </div>
            <div className={Styles.fenzacciContact}>
              <ul>
                <li>
                  <span>
                    <BiLogoGmail />
                  </span>
                  <h4>fenzacci@gmail.com</h4>
                </li>
                <li>
                  <span>
                    <PiPhoneTransferFill />
                  </span>
                  <h4>00 971 502500449</h4>
                </li>
                <li>
                  <a
                    href="https://www.google.com/maps/place/Fenzacci+%D9%81%D9%8A%D9%86%D8%B2%D8%A7%D8%AA%D8%B4%D9%8A%E2%80%AD/@25.2759194,55.2997303,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f43de0d3fc47d:0xefa78a397e33ad5a!8m2!3d25.2759194!4d55.2997303!16s%2Fg%2F11l692vj6g?hl=en-AE&entry=ttu"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      <ImLocation2 />
                    </span>
                    <h4>Fenzacci Store Address</h4>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={Styles.row}>
          <div className={Styles.socailPaymentWrapper}>
            <div className={Styles.section}>
              <h4>Friendly Shopping</h4>
              <span>
                <img src={HumanRight} alt="Human Right" />
              </span>
            </div>

            <div className={Styles.section}>
              <h4 className={Styles.followTitle}>Follow Us</h4>
              <ul>
                {footerSocial &&
                  footerSocial.length > 0 &&
                  footerSocial.map((socialMed, index) => (
                    <li key={index}>
                      <a href={socialMed.url} target="_blank" rel="noreferrer">
                        <img
                          src={`${FENZACCI_BASE_URL}${socialMed.social_img}`}
                          alt={`Footer Social ${index}`}
                          loading="lazy"
                        />
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
            <div className={Styles.section}>
              <div className={Styles.paymentSection}>
                <PaymentAccept />
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.row}>
          <div className={Styles.termsCondition}>
            {termData.map((term, index) => (
              <div className={Styles.termData} key={index}>
                <Link to={`/terms-conditions/${term.content_slug}`}> 
                <h5>{term.content_title}</h5>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FenzacciFooter;
